import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
class PickupRequest extends Component {
constructor(){
    super();
        this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        services:[],
        to_country:'',
        from_country:'',
        weight:'',
        service:'',
        cities:[],
        pricevalue:'',
        currency:0,
        allOrigins:[],
        allDests:[]
    };
    this.onChange = this.onChange.bind(this);
    this.getQuotesDetails = this.getQuotesDetails.bind(this);
    this.twoCalls = this.twoCalls.bind(this);
}
componentDidMount(){
    var customer_id = localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : 1;
    var api_url_city = this.state.base_url+'api.php?type=getQoutes&customer_id='+customer_id;


     fetch(api_url_city)
    .then(res=>res.json())
    .then(result=>{

        this.setState({
            services:result.services,
            currency:result.currency,
            })
    })
    var api_ur_city = this.state.base_url+'api.php?type=getCities';


     fetch(api_ur_city)
    .then(res=>res.json())
    .then(result=>{

        this.setState({
            cities:result
        })
    })


}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));
// }
getQuotesDetails(e) {
    e.preventDefault();

        this.setState({loading:true})
        console.log(this.state)
        const sendData = {
            origin:this.state.to_country,
            destination:this.state.from_country,
            weight:this.state.weight,
            order_type:this.state.service,
        }
        const trackURL = this.state.base_url+'api.php?type=getQoutesDetails';
        fetch(trackURL,
        {
            method: 'POST',
            headers:
            {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body:JSON.stringify(sendData)
        })
        .then((response) => response.json())
        .then(result=>{
            console.log(result)
            this.setState({
                pricevalue:result
            })
                this.setState({loading:false});
        })
        .catch((error) => {
            this.setState({loading:false});
        });
}
twoCalls(e)
{
    console.log('e is'+e)
    this.onChange(e);
    this.getArea(e);
}
getArea(e)
{
    var type = e.target.value;
    var customer_id = localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : 1;
    var area_api = this.state.base_url+'customer_api.php?type=get_areas_with_service&service_type='+type+'&customer_id='+customer_id;
    fetch(area_api)
    .then(res=>res.json())
    .then(result=>{
        console.log(result)
        if (result.response==1) {
            this.setState({
                allOrigins:result.origin,
                allDests:result.destination,
            })
        }
    })
}
onChange(e){
this.setState({[e.target.name]:e.target.value});
}
render(){
      const lang =JSON.parse(localStorage.getItem("lang"));
    const {services, cities} = this.state;
    if (this.state.redirectToReferrer) {
        return(<Redirect to='/track-detail'
    />)
}
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
<form onSubmit={this.getQuotesDetails}>
    <div className="login_screen" id="ratejs_page">
        <CommonHeader />
    <div className="track_shipments desktop_view" >
        <form action="" method="">
            <div className="form_box get_quote">
                <label>{lang.get_quote}</label>
                <input type="date" placeholder="Thu 20 Aug 2020" name=""/>
            </div>

            <div className="form_box get_quote">
                <select style={{padding:'0 18px 0 35px'}} name="service" onChange={this.twoCalls} value={this.state.service_type} required={true} >
        <option selected="" value="">{lang.select_service_type}</option>
        {services && services.map(item=>{
            return(
                <option value={item.id} key={item.service_type}>{item.service_type}</option>

                );
        })}

        </select>
        <svg className="search_box" viewBox="0 0 24 24"><path d="M5.843 9.593L11.5 15.25l5.657-5.657l-.707-.707l-4.95 4.95l-4.95-4.95l-.707.707z" fill="#626262"/></svg>

            </div>

            <div className="form_box get_quote">
                <input type="text" placeholder="Weight" name="weight" onChange={this.onChange} value={this.state.weight} required={true} />

            </div>

            <div className="form_box get_quote">
            <select style={{padding:'0 18px 0 35px'}} name="to_country" onChange={this.onChange} value={this.state.to_country} >
                <option selected="" value="">Please select pickup city</option>
                { (this.state.allOrigins !=='' && this.state.allOrigins.length  > 0) ?
                    this.state.allOrigins.map((value,index)=>{
                        return(
                               <option  key={index} value={value.origin}>{value.origin}</option>
                            );
                    })
                    :
                    ''
                }

                </select>

            </div>
            <div className="form_box get_quote">
            <select style={{padding:'0 18px 0 35px'}} name="from_country" onChange={this.onChange} value={this.state.from_country} >
                <option selected="" value="">{lang.select_distination}</option>
                { (this.state.allDests !=='' && this.state.allDests.length  > 0) ?
                    this.state.allDests.map((value,index)=>{
                        return(
                               <option  key={index} value={value.destination}>{value.destination}</option>
                            );
                    })
                    :
                   ''
                }

                </select>

            </div>

            {
                /*

                    <div className="form_box get_quote package_box Dutiable_box">
                        <label>Dutiable</label>
                        <svg  viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"/></svg>
                        <select>
                            <option>Currency</option>
                            <option>Afghani - AFN</option>
                            <option>Algerian Dinar - DZD</option>
                            <option>Argentine Peso - ARS</option>
                            <option>Ariary - MGA</option>
                        </select>
                        <svg style={{top:'45px'}} className="search_box" viewBox="0 0 24 24"><path d="M5.843 9.593L11.5 15.25l5.657-5.657l-.707-.707l-4.95 4.95l-4.95-4.95l-.707.707z" fill="#212529"/></svg>
                    </div>

                */
            }

        </form>
    </div>
    <div className="shipment_box rate-transits" id="rate_transit">
        <ul className="rate_shipments" id="inner_rate_box">
            <li>
                <input className="service_bg" type="submit" value="Get Rates & Transit Time" />
            </li>

        </ul>
        <div className="form_box get_quote Declared_value rates_box_value " style={{paddingTop:'10px'}}>
                <h5  style={{position:'absolute',left:'25px',top:'23px'}}>{this.state.currency}</h5>
                <input style={{padding:'0 18px 0 32px'}} type="text" placeholder="" name="" value={this.state.pricevalue}/>
            </div>
    </div>



</div>
</form>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
