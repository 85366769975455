import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Header from './Header';
import Footer from './Footer';
import delivery from '../assets/images/delivery.png';
import paymentmethod from '../assets/images/payment-method.png';
import pickup from '../assets/images/pickup.png';
import shipmint from '../assets/images/shipmint.png';
import map from '../assets/images/map-img.png';
import CustomerHeader from '../component/CustomerHeader';
import personalinfo from '../assets/images/personal-info.png';
import bankinfo from '../assets/images/bank-info.png';
import password from '../assets/images/password-info.png';
import checkicon from '../assets/images/checkbox-icon.png';
import checkdone from '../assets/images/check_done.png';
class PickupRequest extends Component {
constructor(){
super();
    this.state = {
        base_url:api_url.base_url,
        redirectToReferrer: false,
        redirectToNext: false,
        cities:[],
        // Details
        bname:'',
        fname:'',
        mobile_no:'',
        email:'',
        address:'',
        cnic:'',
        cnic_copy:'',
        image:'',
        bank_name:'',
        acc_title:'',
        bank_ac_no:'',
        branch_name:'',
        branch_code:'',
        swift_code:'',
        iban_no:'',
        website_url:'',
        city:'',
        customer_type:'',
        product_type:'',
        expected_shipment:'',
        password:'',
        repassword:'',

        confirm_message:"",


        // Errors

        bnameErr:'',
        fnameErr:'',
        mobile_noErr:'',
        emailErr:'',
        addressErr:'',
        cnicErr:'',
        cnic_copyErr:'',
        imageErr:'',
        bank_nameErr:'',
        acc_titleErr:'',
        bank_ac_noErr:'',
        branch_nameErr:'',
        branch_codeErr:'',
        swift_codeErr:'',
        iban_noErr:'',
        website_urlErr:'',
        cityErr:'',
        customer_typeErr:'',
        product_typeErr:'',
        expected_shipmentErr:'',
        passwordErr:'',
        repasswordErr:'',
        loading:false,
        loadingEmail:false,
        emailPass:false,
    };
    this.onChange = this.onChange.bind(this);
    this.nextPayment = this.nextPayment.bind(this);
    this.nextDelivery = this.nextDelivery.bind(this);
    this.firstStep = this.firstStep.bind(this);
    this.submitPrev = this.submitPrev.bind(this);
    this.deliveryPrev = this.deliveryPrev.bind(this);
    this.paymentPrev = this.paymentPrev.bind(this);
    this.getArea = this.getArea.bind(this);
    this.twoCalls = this.twoCalls.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
}

componentDidMount(){


    var api_cities_details = this.state.base_url+'customer_api.php?type=getCities';
    fetch(api_cities_details)
    .then(res=>res.json())
    .then(result=>{
        this.setState({
            cities:result
        })
    })

}

onChange(e){
    this.setState({[e.target.name]:e.target.value});
}

nextOne(value){

}

nextPayment(){
    var active = $('.second_li');
    $('.third_li').addClass('active');
    active.removeClass('active');
    $('#step2').removeClass('active');
    $('#step3').addClass('active');
}
nextDelivery(){

    if (this.state.city == '' && this.state.customer_type == '' && this.state.product_type == '' && this.state.expected_shipment =='') {
        this.setState({
            cityErr:"City Name is required",
            customer_typeErr:"Please Select Account type",
            product_typeErr:"This field is required",
            expected_shipmentErr:"This field is required",
        });
    }else if(this.state.city=="")
    {
        this.setState({
            cityErr:"City Name is required",
            customer_typeErr:"",
            product_typeErr:"",
            expected_shipmentErr:"",
        });
    }else if(this.state.customer_type=="")
    {
        this.setState({
            cityErr:"",
            customer_typeErr:"Please Select Account type",
            product_typeErr:"",
            expected_shipmentErr:"",
        });
    }else if(this.state.product_type=="")
    {
        this.setState({
            cityErr:"",
            customer_typeErr:"",
            product_typeErr:"This field is required",
            expected_shipmentErr:"",
        });
    }else if(this.state.expected_shipment=="")
    {
        this.setState({
            cityErr:"",
            customer_typeErr:"",
            product_typeErr:"",
            expected_shipmentErr:"This field is required",
        });
    }else{
        this.setState({
            cityErr:"",
            customer_typeErr:"",
            product_typeErr:"",
            expected_shipmentErr:"",
        });
       var active = $('.third_li');
        $('.fourth_li').addClass('active');
        active.removeClass('active');
        $('#step3').removeClass('active');
        $('#complete').addClass('active');
    }

}
firstStep(){

    this.setState({loadingEmail:true})
    var validEmailCheck = this.state.base_url+'customer_api.php?type=checkValidEmail&email='+this.state.email;
    fetch(validEmailCheck)
    .then(res=>res.json())
    .then(result=>{
        if (result.response==1) {

            this.setState({loadingEmail:false,emailPass:true},()=>{

                this.setState({
                    bnameErr:"",
                    fnameErr:"",
                    mobile_noErr:"",
                    emailErr:"Email already exists",
                    addressErr:"",
                    cnicErr:"",
                    cityErr:"",
                    loadingEmail:false

                });
            })
        }else{

            this.setState({loadingEmail:false,emailPass:false},()=>{




            if (this.state.bname =='' && this.state.fname =='' && this.state.mobile_no =='' && this.state.email =='' && this.state.address =='' && this.state.cnic =='' ) {
            this.setState({
                bnameErr:"This field is required",
                fnameErr:"(Required)",
                mobile_noErr:"Mobile No is required",
                emailErr:"Email address is required",
                addressErr:"Address is required",
                cnicErr:"CNIC no is required",
            });
        }else if(this.state.bname ===''){
            this.setState({
                bnameErr:"(Required)",
                fnameErr:"",
                mobile_noErr:"",
                emailErr:"",
                addressErr:"",
                cnicErr:"",
            });
        }else if(this.state.fname ===''){
            this.setState({
                bnameErr:"",
                fnameErr:"Personal Name is required",
                mobile_noErr:"",
                emailErr:"",
                addressErr:"",
                cnicErr:"",
            });
        }else if(this.state.mobile_no ===''){
            this.setState({
                bnameErr:"",
                fnameErr:"",
                mobile_noErr:"Mobile No is required",
                emailErr:"",
                addressErr:"",
                cnicErr:"",
            });
        }else if(this.state.email ===''){
            this.setState({
                bnameErr:"",
                fnameErr:"",
                mobile_noErr:"",
                emailErr:"Email address is required",
                addressErr:"",
                cnicErr:"",
            });
        }else if(this.state.address ===''){
            this.setState({
                bnameErr:"",
                fnameErr:"",
                mobile_noErr:"",
                emailErr:"",
                addressErr:"Address is required",
                cnicErr:"",
            });
        }else if(this.state.cnic ===''){
            this.setState({
                bnameErr:"",
                fnameErr:"",
                mobile_noErr:"",
                emailErr:"",
                addressErr:"",
                cnicErr:"CNIC no is required",
            });
        }else{
            this.setState({
                bnameErr:"",
                fnameErr:"",
                mobile_noErr:"",
                emailErr:"",
                addressErr:"",
                cnicErr:"",
            });
            var active = $('.first_li');
            $('.second_li').addClass('active');
            active.removeClass('active');
            $('#step1').removeClass('active');
            $('#step2').addClass('active');
        }
    })
        }
    })
}
submitPrev(){
    var active = $('.third_li');
    $('.fourth_li').removeClass('active');
    active.addClass('active');
    $('#step3').addClass('active');
    $('#complete').removeClass('active');
}
deliveryPrev(){
    var active = $('.second_li');
    $('.third_li').removeClass('active');
    active.addClass('active');
    $('#step2').addClass('active');
    $('#step3').removeClass('active');
}
paymentPrev(){
    var active = $('.first_li');
    $('.second_li').removeClass('active');
    active.addClass('active');
    $('#step1').addClass('active');
    $('#step2').removeClass('active');
}
twoCalls(e)
{
    console.log('e is'+e)
    this.onChange(e);
    this.getArea(e);
}
getArea(e)
{
    var name = e.target.value;
    var area_api = this.state.base_url+'customer_api.php?type=get_areas&cityname='+name;
    fetch(area_api)
    .then(res=>res.json())
    .then(result=>{
        this.setState({
            allAreas:result.areas,
            area:result.areas[0].id
        })
    })
}

submitOrder(e)
{

    e.preventDefault();
    if (this.state.password =="" && this.state.repassword == "") {
        this.setState({
            passwordErr:"(Required)",
            repasswordErr:"(Required)"
        })
    }else if(this.state.password=="")
    {
        this.setState({
            passwordErr:"(Required)",
            repasswordErr:""
        })
    }
    else if(this.state.repassword=="")
    {
        this.setState({
            passwordErr:"",
            repasswordErr:"(Required)"
        })
    }else if(this.state.password != this.state.repassword)
    {
        this.setState({
            passwordErr:"",
            repasswordErr:"(Not matched)"
        })

    }else{
        this.setState({
            passwordErr:"",
            repasswordErr:"",
            loading:true,
        })
       const submitUrl = this.state.base_url+'customer_api.php?type=submit_signup';
        fetch(submitUrl,
        {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(this.state)
        })
        .then((response) => response.json())
        .then(result=>{
            var active = $('.fourth_li');
            $('.fifth_li').addClass('active');
            active.removeClass('active');
            $('#complete').removeClass('active');
            $('#thankyou').addClass('active');
            this.setState({
                confirm_message:result.message,
                bname:'',
                fname:'',
                mobile_no:'',
                email:'',
                address:'',
                cnic:'',
                cnic_copy:'',
                image:'',
                bank_name:'',
                acc_title:'',
                bank_ac_no:'',
                branch_name:'',
                branch_code:'',
                swift_code:'',
                iban_no:'',
                website_url:'',
                city:'',
                customer_type:'',
                product_type:'',
                expected_shipment:'',
                password:'',
                repassword:'',
            })
            this.setState({loading:false});
        })
        .catch((error) => {
            this.setState({loading:false});
        });
    }

}
render(){
    const lang =JSON.parse(localStorage.getItem("lang"));
  if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/dashboard' />)
  }

const {cities} = this.state;
const sTypes = this.state.sTypes ? this.state.sTypes :'';
return(
<React.Fragment>
   <div className="booking_values main_body register_main track_shipment_page" id="desktop_view">
      <div className="login_screen">
         <CustomerHeader title="Signup" />
         <form id="signup_Tabs">
            <div className="wizard">
               <div className="wizard-inner">
                  <div className="connecting-line"></div>
                  <ul className="nav nav-tabs" role="tablist">
                     <li role="presentation" className="first_li active">
                        <a  >
                        <span className="round-tab">
                        <img src={personalinfo} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className="second_li disabled">
                        <a  >
                        <span className="round-tab">
                        <img src={bankinfo} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className=" third_li disabled">
                        <a >
                        <span className="round-tab">
                        <img src={pickup} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className=" fourth_li disabled">
                        <a  title="Complete">
                        <span className="round-tab">
                        <img src={password} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className=" fifth_li disabled">
                        <a  title="Complete">
                        <span className="round-tab">
                        <img src={checkdone} alt=""/>
                        </span>
                        </a>
                     </li>
                  </ul>
               </div>

                  <div className="tab-content-">
                  <div className="carge_location_map"><img src={map} alt=""/></div>
                     <div className="tab-pane active" role="tabpanel" id="step1">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.personal_info}</h4>
                                  <div className="inner_pickupinfo">
                                  <svg  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.company_name}<h6>{this.state.bnameErr}</h6></label>
                                    <input type="text" name="bname"   placeholder={lang.bussiness_name} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.person_of_contact}<h6>{this.state.fnameErr}</h6></label>
                                    <input type="text" name="fname"  placeholder={lang.person_of_contact} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.phone_number}<h6>{this.state.mobile_noErr}</h6></label>
                                    <input type="text" placeholder= {lang.phone_number} name="mobile_no" onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                  <svg  viewBox="0 0 24 24"><path d="M21 9v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9c0-1.11.603-2.08 1.5-2.598l-.003-.004l8.001-4.62l8.007 4.623l-.001.003A2.999 2.999 0 0 1 21 9zM3.717 7.466L11.5 12.52l7.783-5.054l-7.785-4.533l-7.781 4.533zm7.783 6.246L3.134 8.28A1.995 1.995 0 0 0 3 9v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V9c0-.254-.047-.497-.134-.72L11.5 13.711z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.email}<h6>{this.state.emailErr}</h6></label>
                                    <input type="email" name="email"  placeholder={lang.email} onChange={this.onChange} />
                                  </div>

                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#d99728"/></svg>
                                    <label><span>*</span>  {lang.company_name} / {lang.pickup_city} <h6>{this.state.addressErr}</h6></label>
                                    <input type="text" name="address" placeholder={lang.address} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo ">
                                    <svg viewBox="0 0 24 24"><path d="M5 3h13a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11.586l4.293-4.293l2.5 2.5l5-5L20 16V6a2 2 0 0 0-2-2H5zm4.793 13.207l-2.5-2.5L3 19a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-1.586l-5.207-5.207l-5 5zM7.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" fill="#d99728"/></svg>
                                    <label><span>*</span>  {lang.cnic} <h6>{this.state.cnicErr}</h6></label>
                                    <input type="text" name="cnic" placeholder={lang.cnic}  onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo file_type">
                                    <svg viewBox="0 0 24 24"><path d="M5 3h13a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11.586l4.293-4.293l2.5 2.5l5-5L20 16V6a2 2 0 0 0-2-2H5zm4.793 13.207l-2.5-2.5L3 19a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-1.586l-5.207-5.207l-5 5zM7.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" fill="#d99728"/></svg>
                                    <label>{lang.cnic_copy} </label>
                                    <input className="custom-file-input" name={lang.cnic_copy} type="file" placeholder=""  onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo file_type">
                                    <svg  viewBox="0 0 24 24"><path d="M7 3h9a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-3v6.7l-3-2.1l-3 2.1V4zm5 0H8v4.78l2-1.401l2 1.4V4zM9 19v-2H7v-1h2v-2h1v2h2v1h-2v2H9z" fill="#d99728"/></svg>
                                    <label>{lang.logo_img} </label>
                                    <input className="custom-file-input" type="file" name="image" placeholder={lang.logo_img} onChange={this.onChange} />
                                  </div>
                              </div>


                            </div>

                            <ul className="list-inline ">
                               <li>
                               <button className="next-step next-step_pay"  type="button" onClick={this.firstStep} disabled={this.state.loadingEmail}>
                                  {this.state.loadingEmail && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ margin: "3px 0 0 -18px" ,    position: 'absolute'}}
                                    />
                                  )}
                                  {this.state.loadingEmail && <span>{lang.loading}</span>}
                                  {!this.state.loadingEmail && <span>{lang.next}</span>}
                                </button></li>
                            </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="step2">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.bank_information}</h4>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path d="M11 2.5L20 7v2H2V7l9-4.5zm4 7.5h4v8h-4v-8zM2 22v-3h18v3H2zm7-12h4v8H9v-8zm-6 0h4v8H3v-8zm0 10v1h16v-1H3zm1-9v6h2v-6H4zm6 0v6h2v-6h-2zm6 0v6h2v-6h-2zM3 8h16v-.4l-8-4.019l-8 4.02V8z" fill="#d99728"/></svg>
                                    <label>{lang.bank_name}</label>
                                    <input type="text" name="bank_name"   placeholder={lang.bank_name} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#d99728"/></svg>
                                    <label>{lang.account_title}</label>
                                    <input type="text" name="acc_title"  placeholder={lang.account_title} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M5 5h13a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v1h17V8a2 2 0 0 0-2-2H5zM3 17a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-5H3v5zm2-1h4v1H5v-1zm6 0h3v1h-3v-1zm-8-6v1h17v-1H3z" fill="#d99728"/></svg>
                                    <label>{lang.account_number}</label>
                                    <input type="text" name="bank_ac_no" placeholder={lang.account_number}  onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                  <svg  viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#d99728"/></svg>
                                    <label>{lang.branch_name}</label>
                                    <input type="text" name="branch_name"  placeholder={lang.branch_name} onChange={this.onChange} />
                                  </div>

                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zM6 11h11v1H6v-1zm0 3h11v1H6v-1zm0 3h9v1H6v-1z" fill="#d99728"/></svg>
                                    <label>{lang.branch_code}</label>
                                    <input type="text" name="branch_code" placeholder={lang.branch_code} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M5 3h13a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-4.586l-3.707 3.707A1 1 0 0 1 8 21v-3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm13 1H5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h4v4l4-4h5a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zM5 7h13v1H5V7zm0 3h12v1H5v-1zm0 3h8v1H5v-1z" fill="#d99728"/></svg>
                                    <label>{lang.swift_code}</label>
                                    <input type="text" name="swift_code" placeholder={lang.swift_code}  onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo ">
                                    <svg viewBox="0 0 24 24"><path d="M5 3h13a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11.586l4.293-4.293l2.5 2.5l5-5L20 16V6a2 2 0 0 0-2-2H5zm4.793 13.207l-2.5-2.5L3 19a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-1.586l-5.207-5.207l-5 5zM7.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" fill="#d99728"></path></svg>
                                    <label>{lang.iban}</label>
                                    <input type="text" name="iban_no" placeholder={lang.iban}  onChange={this.onChange} />
                                  </div>

                              </div>


                            </div>
                        <ul className="list-inline pull-right second_step">
                           <li><button type="button" className="prev-step prev-step_pick" onClick={this.paymentPrev}>{lang.Previous}</button></li>
                           <li><button type="button" className="next-step next-step_pick" onClick={this.nextPayment}>{lang.next}</button></li>
                        </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="step3">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.shipping_info}</h4>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path d="M6 5h13a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-4.508L15 21h1v1H9v-1h1l.508-3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm5.508 13L11 21h3l-.508-3h-1.984zM6 6a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H6zM1 8a5 5 0 0 1 5-5h12v1H6a4 4 0 0 0-4 4v6H1V8z" fill="#d99728"/></svg>
                                    <label>{lang.web_link}</label>
                                    <input type="text" name="website_url"   placeholder="Website / Facebook Page" onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">

                                    <label><span>*</span>  {lang.select_city} <h6>{this.state.cityErr}</h6></label>
                                    <select className="form-control origin" name="city" onChange={this.onChange}>
                                        <option>{lang.select}</option>
                                        { (this.state.cities !=='' && this.state.cities.length  > 0) ?
                                            this.state.cities.map((value,index)=>{
                                                return(
                                                       <option key={index} value={value.city_name}>{value.city_name}</option>
                                                    );
                                            })
                                            :
                                            ''
                                        }
                                    </select>
                                  </div>

                                  <div className="inner_pickupinfo">

                                    <label><span>*</span>{lang.account_nature}<h6>{this.state.customer_typeErr}</h6></label>
                                    <select name="customer_type" onChange={this.onChange}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="0">{lang.cash_on_deli}</option>
                                        <option value="1">{lang.coprate_invoices} </option>
                                    </select>
                                  </div>
                                  <div className="inner_pickupinfo">

                                    <label><span>*</span>{lang.product_type}<h6>{this.state.product_typeErr}</h6></label>
                                    <select name="product_type" onChange={this.onChange}>
                                        <option value="" disabled selected>{lang.select}</option>
                                        <option value="Apparel">{lang.apparel}</option><option value="Automotive Pants">{lang.auto_pants}</option><option value="Accessories">{lang.accessories}</option><option value="Gadgets">{lang.gadgets}</option><option value="Cosmetics">{lang.cosmetics}</option><option value="Jewellry">{lang.jewellry}</option><option value="Stationary">{lang.stationary}</option><option value="Handicrafts">{lang.handicraft}</option><option value="Footwear">{lang.footwear}</option><option value="Organic &amp; Health Products">{lang.organic} &amp; {lang.health_pro}</option><option value="Appliances or Electronics">{lang.applience_electronics}</option><option value="Home Decor or Interior items">{lang.home_decore}</option><option value="Toys">{lang.toys}</option><option value="Fitness items">{lang.fitness}</option><option value="MarketPlace">{lang.market_place}</option><option value="Document &amp; Letters">{lang.document} &amp; {lang.letter}</option><option value="Others">{lang.other}</option>
                                    </select>
                                  </div>

                                  <div className="inner_pickupinfo">
                                  <svg  viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#d99728"/></svg>
                                    <label><span>*</span>{lang.expected_avrg} / {lang.month}<h6>{this.state.expected_shipmentErr}</h6></label>
                                    <input type="text" name="expected_shipment"  placeholder="" onChange={this.onChange} />
                                  </div>


                              </div>


                            </div>
                        <ul className="list-inline pull-right second_step">
                           <li><button type="button" className="prev-step prev-step_deliver" onClick={this.deliveryPrev}>{lang.previous}</button></li>
                           <li><button type="button" className="next-step next-step_deliver" onClick={this.nextDelivery}>{lang.next}</button></li>
                        </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="complete">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.password}</h4>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path fill-opacity=".886" d="M16 8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3V6.5a4.5 4.5 0 1 1 9 0V8zM7 9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H7zm8-1V6.5a3.5 3.5 0 0 0-7 0V8h7zm-3.5 6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm0-1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.password}<h6>{this.state.passwordErr}</h6></label>
                                    <input type="password" name="password"   placeholder={lang.password} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                  <svg viewBox="0 0 24 24"><path fill-opacity=".886" d="M16 8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3V6.5a4.5 4.5 0 1 1 9 0V8zM7 9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H7zm8-1V6.5a3.5 3.5 0 0 0-7 0V8h7zm-3.5 6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm0-1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5z" fill="#d99728"/></svg>
                                    <label><span>*</span> {lang.confirm_password}<h6>{this.state.repasswordErr}</h6></label>
                                    <input type="password" name="repassword"   placeholder={lang.confirm_password} onChange={this.onChange} />
                                  </div>
                              </div>
                              <div className="confirm_message_box">
                                  {this.state.confirm_message}
                              </div>
                            </div>
                            <ul className="list-inline pull-right second_step">
                            <li><button type="button" className="prev-step prev-step_pick" onClick={this.submitPrev}>{lang.previous}</button></li>
                           <li>
                           <button className="next-step"  type="button" onClick={this.submitOrder} disabled={this.state.loading}>
                                  {this.state.loading && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px" }}
                                    />
                                  )}
                                  {this.state.loading && <span>Loading...</span>}
                                  {!this.state.loading && <span>Submit</span>}
                                </button>
                                </li>
                        </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="thankyou">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                              </div>
                              <div className="confirm_message_box">
                                  <img src={checkicon} alt=""/>
                                  <p>{this.state.confirm_message}</p>
                              </div>
                            </div>

                     </div>
                     <div className="clearfix"></div>
                  </div>

            </div>
         </form>
      </div>
   </div>
  <div className="bottom_bar right_btmbar">
    <ul>
      <li >
        <Link to="/">
         <svg aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#545353"/></svg>
        <b>{lang.home}</b>
        </Link>
      </li>
      <li>
       <Link to="/orders">

          <svg  viewBox="0 0 24 24"><path d="M16 18a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-9-1a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM18 6H4.273l2.547 6H15a.994.994 0 0 0 .8-.402l3-4h.001A1 1 0 0 0 18 6zm-3 7H6.866L6.1 14.56L6 15a1 1 0 0 0 1 1h11v1H7a2 2 0 0 1-1.75-2.97l.72-1.474L2.338 4H1V3h2l.849 2H18a2 2 0 0 1 1.553 3.26l-2.914 3.886A1.998 1.998 0 0 1 15 13z" fill="#545353"/></svg>
        <b>{lang.orders}</b>
       </Link>
      </li>
      <li>
        <Link to="/booking">
           <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#545353"/></svg>
        <b>{lang.booking}</b>
        </Link>
      </li>
    </ul>
    </div>
</React.Fragment>
)
}
}
export default PickupRequest;
