import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import loader from "../assets/images/770.gif";
import $ from "jquery";
import { api_url } from "../Config";
import Header from "./Header";
import swal from "sweetalert";
import Footer from "./Footer";
import CustomerHeader from "../component/CustomerHeader";
class Home extends Component {
  constructor() {
    super();
    this.state = {
      redirectToReferrer: false,
      base_url: api_url.base_url,
      email: "",
      password: "",
      allStatus: [],
      no_record: false,
      loading: false,
      pendingcod: 0,
      totalcod: 0,
      goToOrder: false,
      translation_array: [],
    };
    let lang_name = localStorage.getItem("lang_name");
    if (lang_name == null) {
      localStorage.setItem("lang", JSON.stringify(this.state.english));
      localStorage.setItem("lang_name", "english");
    }
    this.onChange = this.onChange.bind(this);
    this.Logout = this.Logout.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    let lang_name = localStorage.getItem("lang_name");
    let customerData = {
      customer_id: localStorage.getItem("customer_id"),
      lang: lang_name,
    };
    const addUrl =
      this.state.base_url + "customer_api.php?type=getDashboardData";
    fetch(addUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerData),
    })
      .then((response) =>
        response.json().then((result) => {
          if (result.response == 1) {
            this.setState({
              allStatus: result.status,
              totalcod: result.total_cod,
              pendingcod: result.pending_cod,
              translation_array: result.translation,
              loading: false,
            });
            localStorage.setItem(
              "trnas_array",
              JSON.stringify(result.translation)
            );
          }
        })
      )
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  componentWillMount() {
    if (localStorage.getItem("customer_id")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("customer_id"));
  }

  Logout() {
    console.log("in logout");
    localStorage.clear();
    console.log(localStorage);
  }

  moveToOrders(status) {
    sessionStorage.setItem("search_status", status);
    this.setState({ goToOrder: true });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    let lang_name = localStorage.getItem("lang_name");
    if (lang_name == null) {
      localStorage.setItem("lang", JSON.stringify(this.state.english));
      localStorage.setItem("lang_name", "english");
    }
    const lang = JSON.parse(localStorage.getItem("lang"));
    const lang_array = JSON.parse(localStorage.getItem("trnas_array"))
      ? JSON.parse(localStorage.getItem("trnas_array"))
      : [];
    if (this.state.goToOrder) {
      return <Redirect to="orders" />;
    }
    if (this.state.redirectToReferrer) {
      return <Redirect to="from-me-shipment" />;
    }
    return (
      <React.Fragment>
        <div id="page-name">
          <div
            className="booking_values main_body register_main track_shipment_page"
            id="desktop_view"
          >
            <div className="login_screen">
              <CustomerHeader title="Dashboard" />
              <div className="shipment_box_new contact_page dashboard_page">
                <p>
                  <b>{lang.dashboard}</b> {lang.lets_get_a_quick}
                </p>
                <ul>
                  {this.state.allStatus != "" ? (
                    this.state.allStatus.map((value, index) => {
                      var status_name = value.status_name
                        ? value.status_name
                        : "";

                      lang_array.length > 0 &&
                        lang_array.map((trnas_val) => {
                          if (trnas_val.keyword == status_name) {
                            status_name = trnas_val.translation;
                          }
                        });

                      return (
                        <Link
                          key={index}
                          onClick={(e) => this.moveToOrders(value.status_name)}
                        >
                          <li>
                            <a key={index} className="service_bg">
                              <svg viewBox="0 0 24 24">
                                <path
                                  d="M4.996 5h5v5h-1V6.493a6.502 6.502 0 0 0 2.504 12.5a6.5 6.5 0 0 0 1.496-12.827V5.142A7.5 7.5 0 1 1 7.744 6H4.996V5z"
                                  fill="#fff"
                                />
                              </svg>
                              <h3>{status_name}</h3>
                              <h2>{value.status_count}</h2>
                            </a>
                          </li>
                        </Link>
                      );
                    })
                  ) : (
                    <img
                      className="loader_onorder"
                      src={loader}
                      alt="Loading data"
                    />
                  )}
                  {this.state.loading ? (
                    ""
                  ) : (
                    <React.Fragment>
                      {/* <li>
                        <a className="rate_bg">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z"
                              fill="#fff"
                            />
                          </svg>
                          <h3>{lang.pending_cod_amount}</h3>
                          <h2>{this.state.pendingcod}</h2>
                        </a>
                      </li> */}
                      {/* <li>
                                        <a  className="rate_bg">
                                            <svg  viewBox="0 0 24 24"><path d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z" fill="#fff"/></svg>
                                            <h3> {lang.total_cod}</h3>
                                            <h2>{this.state.totalcod}</h2>
                                        </a>
                                    </li> */}
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default Home;
